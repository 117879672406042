<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import type { PropType } from 'vue'
import type { DropdownOptionInterface } from '@voix/types'

import VueMultiselect from 'vue-multiselect'

import SectionLabel from '@voix/components/chrome/SectionLabel.vue'

export default defineComponent({
  components: { SectionLabel, VueMultiselect },
  props: {
    modelValue: {
      type: null,
      required: false,
      default: null,
    },
    options: {
      type: Array as PropType<DropdownOptionInterface[]>,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Select an Option',
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    border: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['input', 'update:modelValue'],

  setup(props, { emit, slots }) {
    // In the case that there is no modelValue, we need to use a local value
    const onlyLocalValue = ref(null)

    const localValue = computed({
      get: () => {
        if (props.modelValue)
          return props.modelValue

        return onlyLocalValue.value
      },
      set: (value) => {
        emit('input', value)
        emit('update:modelValue', value)

        if (!props.modelValue)
          onlyLocalValue.value = value
      },
    })

    const multiSelectOptions = computed(() =>
      props.options.map(option => ({
        name: option.label,
        value: option.value,
      })),
    )

    const multiSelectValue = computed({
      get() {
        return props.modelValue
          ? props.options
            .filter(option => props.modelValue.includes(option.value))
            .map(option => ({
              name: option.label,
              value: option.value,
            }))
          : []
      },
      set(value) {
        const v = JSON.parse(
          JSON.stringify(value.map(option => option.value)),
        )
        emit('update:modelValue', v)
      },
    })

    return {
      localValue,
      multiSelectOptions,
      multiSelectValue,
      props,
      slots,
    }
  },
})
</script>

<template>
  <div>
    <SectionLabel v-if="props.label">
      {{ props.label }}
    </SectionLabel>
    <div
      class="relative flex items-center cursor-pointer pt-1"
      :class="{
        'border border-transparent hover:border-gray-100': border,
      }"
    >
      <slot />

      <div
        v-if="!multiple && props.options.length > 0"
        class="relative w-full"
      >
        <select
          :id="props.id"
          v-model="localValue"
          class="cursor-pointer py-2 px-2 border rounded border-gray-200 text-xs w-full h-full text-gray-500 bg-transparent placeholder-gray-300 focus:ring-gray-100 focus:outline-gray-200 appearance-none"
          :class="{
            '-ml-5 pl-8': slots.default,
            '': !slots.default,
          }"
          :placeholder="placeholder"
        >
          <option
            v-for="option in props.options"
            :key="option.key"
            :value="option.value"
            :placeholder="props.placeholder"
          >
            {{ option.label }}
          </option>
        </select>

        <div class="absolute z-10 top-0 mt-2 mr-2 right-0 bg-gray-200 text-gray-500 rounded w-5 h-5 flex items-center justify-center pointer-events-none">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          </svg>
        </div>
      </div>

      <VueMultiselect
        v-if="multiple"
        v-model="multiSelectValue"
        :options="multiSelectOptions"
        :placeholder="placeholder"
        :searchable="searchable"
        :multiple="true"
        :close-on-select="false"
        label="name"
        track-by="name"
        class="voix-multiselect border-0 text-xs w-full h-full text-gray-500 bg-transparent placeholder-gray-300 focus:ring-gray-300"
        data-voix
      />
    </div>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss">
  .voix-multiselect.multiselect {
    @apply text-xs;
  }

  .voix-multiselect .multiselect__tag {
    @apply text-2xs;
  }
  .voix-multiselect .multiselect__tag-icon {
    @apply leading-tight;
  }
  .voix-multiselect .multiselect__option,
  .voix-multiselect .multiselect__option::after {
    @apply leading-tight py-2 text-xs min-h-[auto];
  }
</style>
